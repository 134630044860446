<template>
  <UiModalOrDropdown
    ref="modalOrDropdown"
    custom-design-footer="p-0"
    @shown="modalOrDropdownShown"
    @hidden="modalOrDropdownHidden"
  >
    <template #trigger>
      <button class="w-full flex flex-col relative" @click="showModalAndScroll">
        <UiDaterangeInput
          v-if="!computedState.variabilitaet"
          :from="computedState.von"
          :to="computedState.bis"
          :highlight-when-empty="highlightWhenEmpty"
          design="normal"
          label="normal"
        />
        <UiFakeInput
          v-if="computedState.variabilitaet"
          :model-value="`${ t('between') } ${computedState.von} - ${computedState.bis}`"
          inputstyle="rounded-xl w-full cursor-pointer"
        >
          <template #icon>
            <UiIcon src="/svg/icons/calendar.svg" width="22" height="22" class="fill-neutral-500" />
          </template>
          <template #label>
            {{ computedState.variabilitaet }} {{ t(`nights.${numerus(computedState.variabilitaet)}`, { nights: computedState.variabilitaet }) }}
          </template>
        </UiFakeInput>
      </button>
    </template>
    <template #head>
      <div class="flex text-center w-auto justify-center">
        <div
          v-if="flexSearch"
          class="bg-neutral-50 rounded-full p-1 w-auto flex flex-row"
        >
          <UiButton
            :color="showFlexSearch ? 'light-no-active-change' : 'petrol'"
            buttonstyle="rounded-full"
            buttonpadding="py-2 px-8"
            @click="activateNormalSearch"
          >
            {{ t('exact_date') }}
          </UiButton>
          <UiButton
            :color="showFlexSearch ? 'petrol' : 'light-no-active-change'"
            buttonstyle="rounded-full"
            buttonpadding="py-2 px-8"
            @click="activateFlexSearch"
          >
            {{ t('flex_date') }}
          </UiButton>
        </div>
        <div
          v-else
          class="py-2 px-5 text-neutral-800 font-semibold rounded-full text-xl"
        >
          {{ t('travel_date') }}
        </div>
      </div>
      <div v-if="showFlexSearch" class="flex md:hidden flex-col items-center justify-center -mx-8 md:mx-0 mt-5">
        <div class="text-xl font-semibold mb-2">
          {{ t('flex_search_question_one') }}
        </div>
        <div>
          <UiSelect
            :model-value="localFlexState.variabilitaet"
            design="pill"
            @update:model-value="localFlexState.variabilitaet = $event"
          >
            <option value="0">
              {{ t('number_of_nights') }}
            </option>
            <option v-for="value in nightsRange" :key="value" :value="value" class="text-center">
              {{ value }} {{ t(`nights.${numerus(value)}`, { nights: value }) }}
            </option>
          </UiSelect>
        </div>
      </div>
    </template>
    <template #default>
      <div v-if="showFlexSearch" class="hidden md:flex flex-col items-center justify-center -mx-8 md:mx-0 mt-5 md:-mt-3">
        <div class="text-xl font-semibold mb-2">
          {{ t('flex_search_question_one') }}
        </div>
        <div>
          <UiSelect
            :model-value="localFlexState.variabilitaet"
            design="pill"
            @update:model-value="localFlexState.variabilitaet = $event"
          >
            <option value="0">
              {{ t('number_of_nights') }}
            </option>
            <option v-for="value in nightsRange" :key="value" :value="value" class="text-center">
              {{ value }} {{ t(`nights.${numerus(value)}`, { nights: value }) }}
            </option>
          </UiSelect>
        </div>
      </div>
      <template v-if="showFlexSearch">
        <div class="flex flex-col items-center justify-center">
          <div class="text-xl font-semibold -mt-[1.6rem] md:mt-1 mb-8">
            {{ t('flex_search_question_two') }}
          </div>
        </div>
        <UiDateRangePicker
          :from="localFlexState.von"
          :to="localFlexState.bis"
          :months="modalOrDropdown?.isModal ? 2 : 2"
          :scrollable="scrollable"
          :date-range-check="dateRangeCheckFlex"
          :reset-button="false"
          :is-flex="true"
          @update:from="localFlexState.von = $event"
          @update:to="localFlexState.bis = $event"
        />
      </template>
      <template v-else>
        <UiDateRangePicker
          :from="localNormalState.von"
          :to="localNormalState.bis"
          :months="modalOrDropdown?.isModal ? 2 : 2"
          :scrollable="scrollable"
          :date-range-check="dateRangeCheckNormal"
          :reset-button="false"
          :tooltip-active="true"
          @update:from="localNormalState.von = $event"
          @update:to="localNormalState.bis = $event"
          @selected:to="modalOrDropdown?.hide"
        />
      </template>
    </template>
    <template #foot>
      <div class="relative">
        <template v-if="showFlexSearch">
          <div class="px-5 pb-5 md:pb-0 md:px-7">
            <UiAlert
              v-if="missingData && (!localFlexState.von || !localFlexState.bis || !localFlexState.variabilitaet)"
              design="info"
              class="w-full mt-5 text-left"
            >
              <span v-if="!localFlexState.variabilitaet" class="block">
                {{ t('alert_no_nights') }}
              </span>
              <span v-if="!localFlexState.von || !localFlexState.bis" class="block">
                {{ t('alert_no_date') }}
              </span>
            </UiAlert>
          </div>
          <div
            class="flex items-center justify-end gap-3 md:border-t-0 border-t border-neutral-100 px-5 pb-2 pt-2 md:px-7 md:pt-8 md:pb-4"
          >
            <UiButton
              v-if="localFlexState.von || localFlexState.bis || localFlexState.variabilitaet"
              color="primary"
              @click="resetAll"
            >
              {{ t('reset') }}
            </UiButton>
            <UiButton
              color="petrol"
              @click="applyFilter"
            >
              {{ t('apply') }}
            </UiButton>
          </div>
        </template>
        <template v-else>
          <div v-if="localNormalState.von">
            <div
              class="flex items-center justify-end gap-3 md:border-t-0 border-t border-neutral-100 px-5 pb-2 pt-2 md:px-7 md:pt-8 md:pb-4"
            >
              <UiButton
                v-if="localNormalState.von || localNormalState.bis"
                color="primary"
                @click="resetAll"
              >
                {{ t('reset') }}
              </UiButton>
            </div>
          </div>
        </template>
        <div ref="scrollTargetDaterangepicker" class="h-[20px] bottom-[-20px] absolute w-full" />
      </div>
    </template>
  </UiModalOrDropdown>
</template>

<script setup lang="ts">
import { range } from '~~/utils/Utils'

interface State {
  von: string,
  bis: string,
  variabilitaet: number
}

const props = defineProps({
  params: {
    type: Object as PropType<State>,
    default: () => ({})
  },
  flexSearch: {
    type: Boolean,
    default: true
  },
  highlightWhenEmpty: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update', 'shown', 'hidden', 'reset'])
const { translate: t, numerus } = useTranslations('SearchFilterTravelDates')

const modalOrDropdown = ref()
const missingData = ref(false)
const showFlexSearch = ref(false)
const scrollTargetDaterangepicker = ref()

abstract class AbstractStateClass {
  public von: string = ''
  public bis:string = ''
  public variabilitaet: number = 0

  public setValues (values: State) {
    this.von = values.von ?? ''
    this.bis = values.bis ?? ''
    this.variabilitaet = values.variabilitaet ?? 0
  }

  public reset () {
    this.von = ''
    this.bis = ''
    this.variabilitaet = 0
  }

  public hasBothDates (): boolean {
    return Boolean(this.von && this.bis)
  }

  public countNights (): number|undefined {
    if (this.hasBothDates()) {
      return ExtendedDate.countNightsStatic(this.von, this.bis)
    }
  }

  public abstract isComplete (): boolean
  public abstract isEmpty (): boolean
}

class NormalStateClass extends AbstractStateClass {
  public isComplete (): boolean {
    return this.hasBothDates()
  }

  public isEmpty (): boolean {
    return !this.von && !this.bis
  }
}

class FlexStateClass extends AbstractStateClass {
  public isComplete (): boolean {
    return Boolean(this.von && this.bis && this.variabilitaet)
  }

  public isEmpty (): boolean {
    return !this.von && !this.bis && !this.variabilitaet
  }
}

const localNormalState = reactive(new NormalStateClass())
const localFlexState = reactive(new FlexStateClass())

const scrollable = computed(() => {
  return (modalOrDropdown.value?.isModal) ? 'scrollable' : ''
})

const computedState = computed(() => {
  if (modalOrDropdown.value?.isOpen) {
    if (showFlexSearch.value) {
      return localFlexState
    } else {
      return localNormalState
    }
  }
  return {
    von: props.params.von,
    bis: props.params.bis,
    variabilitaet: props.params.variabilitaet
  }
})

const update = (values: State) => {
  emit('update', { von: values.von, bis: values.bis, variabilitaet: values.variabilitaet })
}

const modalOrDropdownShown = () => {
  showFlexSearch.value = props.params.variabilitaet > 0
  emit('shown')

  if (showFlexSearch.value) {
    localFlexState.setValues(props.params)
    localNormalState.reset()
  } else {
    localNormalState.setValues(props.params)
    localFlexState.reset()
  }
}

const modalOrDropdownHidden = (reason?: string) => {
  const state = showFlexSearch.value ? localFlexState : localNormalState
  if (!state.isComplete() || (showFlexSearch.value && reason !== 'apply-button-clicked')) {
    state.setValues(props.params)
  } else {
    update(state)
  }

  emit('hidden')
}
const applyFilter = () => {
  if (!localFlexState.bis || !localFlexState.von || !localFlexState.variabilitaet) {
    missingData.value = true
  } else {
    missingData.value = false
    modalOrDropdown.value.hide('apply-button-clicked')
  }
}
const resetAll = () => {
  localNormalState.reset()
  localFlexState.reset()
  missingData.value = false
  update(computedState.value)
}
function dateRangeCheckNormal (from, to) {
  if (from && !to) {
    return !from.isPast()
  } else {
    return to.isAfter(from)
  }
}
function dateRangeCheckFlex (from, to) {
  if (from && !to) {
    return !from.isPast()
  } else {
    if (localFlexState.variabilitaet > 0) {
      return to.isAfter(from.addDays(localFlexState.variabilitaet - 1, true))
    }

    return to.isAfter(from)
  }
}
const nightsRange = computed(() => {
  let nights: number|undefined = 30

  if (localFlexState.hasBothDates()) {
    nights = localFlexState.countNights()
  }

  return range(1, nights)
})
const activateFlexSearch = () => {
  showFlexSearch.value = true
}
const activateNormalSearch = () => {
  showFlexSearch.value = false
}
const showModalAndScroll = () => {
  modalOrDropdown.value?.show()
}
</script>
